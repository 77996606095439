export const CLAIM_CARE = {
  accident: 'Accident',
  illness: 'Maladie',
  pharmacy: 'Pharmacie',
  analysis: 'Analyse et imagerie',
  equipment: 'Appareillage',
  optical: 'Optique',
  dental: 'Soins dentaires',
  hospitalization: 'Hospitalisation',
  consultation: 'Consultation',
  hearing: 'Audition',
  other: 'Autre chose',
};

export const CLAIM_STATUS = {
  pending: 'Non traité',
  waiting: 'Mis en attente',
  paid: 'Payé',
  refused: 'Refusé',
  in_payment: 'Paiement en cours',
  payment_started: 'Paiement démarré',
  waiting_validation: 'En attente de validation',
  payment_failed: 'Echec du paiement',
};

export const CLAIM_COLORS = {
  refused: '#ff00002b',
  in_payment: '#e17a002b',
  payment_started: '#e17a002b',
  waiting_validation: '#e17a002b',
  payment_failed: '#e17a002b',
  paid: '#0081322b',
  pending: 'transparent',
  waiting: '#e17a002b',
};

export const ANIMAL_TYPE = {
  dog: 'Chien',
  cat: 'Chat',
};

export const ANIMAL_GENDER = {
  male: 'Mâle',
  female: 'Femelle',
};

export const CONTRACTITEMTYPE_DISPLAY = {
  health: 'Santé',
  prev: 'Prévention',
};

export const CONTRACTITEMTYPE_COLORS = {
  health: '#0080001c',
  prev: '#5fc3ff61',
  guard: '#cd5fff61',
};

export const STATUS_DISPLAY = {
  insured: 'Assuré',
  not_employed: 'Résilié',
  pending: 'Invité',
  exempted: 'Dispensé',
  conge: 'Congé parental',
  portability: 'Portabilité',
  tns: 'Travailleur non salarié',
  retired: 'Retraité',
  coming: 'Arrivée de mutation',
  leaving: 'Départ pour mutation',
  none: 'Aucun contrat chez CGRM',
};

export const STATUS_COLORS = {
  insured: '#0080001c',
  not_employed: '#ff00004f',
  pending: '#ffdd001c',
  exempted: '#ffb45f80',
  conge: '#5fc3ff61',
  portability: '#cd5fff61',
};

export const CI_STATUS_DISPLAY = {
  active: 'En cours',
  resiliated: 'Résilié',
  pending: 'En attente',
};

export const CI_STATUS_COLORS = {
  active: '#0080001c',
  resiliated: '#ff00004f',
  pending: '#ffdd001c',
};

export const SUIVI_STATUS = {
  called: 'Créé',
  paid: 'Payé',
  due: 'Non payé',
  suspended: 'Suspendu',
};

export const SUIVI_COLORS = {
  paid: '#0080001c',
  due: '#ff00004f',
  called: '#5fc3ff61',
  suspended: '#ffb45f80',
};

export const REASON_LABELS = {
  cycle: 'Paiement recurrent',
  upgrade: 'Augmentation de garantie',
  downgrade: 'Diminution de garantie',
  resiliation: 'Résiliation',
  update: 'Ajout nouvel animal',
  create: 'Creation de contrat',
};

export const QUOTE_TYPE_LABELS = {
  prev: 'Prévention',
  dog: 'Chien',
  cat: 'Chat',
  multiple: 'Multiple',
  guard: 'Garde',
  rates: 'Modification garanties',
  conversion: 'Conversion de contrat',
};

export const OFFER_LABELS = {
  wakam: 'Wakam',
  oney_drooky: 'Drooky',
  oney_alcampo: 'Alcampo',
};

export const PERMISSION_DROOKY_ADMIN = 'drookyAdmin';
export const PERMISSION_DROOKY_READONLY = 'drookyReadOnly';
export const PERMISSION_ETIQ_ADMIN = 'etiqAdmin';
export const PERMISSION_ETIQ_READONLY = 'etiqReadOnly';
